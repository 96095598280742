import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";




export default function Revenuescore() {
    return (
        <div>
            <Header2 />
       
        <div className="page-wraper">
            <div className="Probdesc-page">
                
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Revenue Score</h2>
                            <p>The revenue score measures the strength of your initiatives to monetize.</p>
                        </div>
                        <div className="table-list Revenuescore-table">
                            <Table striped  responsive >

                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <h2>Motivation Parity</h2>
                                                {/* <div className="parity-form cont">
                                                    <input placeholder="Locked" type="text" class="form-control"/>
                                                </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Revenuescore-parity ">
                                                <h2>Purchase Decision Alignment Score</h2>
                                                {/* <div className="parity-form cont">
                                                    <input placeholder="Locked" type="text" class="form-control"/>
                                                </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <h2>Pricing Band</h2>
                                                <div className="parity-form">
                                                    <div className="revenue-check-button">
                                                        <ul>
                                                        <li>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check
                                                                            inline
                                                                            label="Comodity"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />                                                                    
                                                                    </div>
                                                                ))}
                                                            </li>
                                                            <li>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check
                                                                            inline
                                                                            label="Premium"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                        />                                                                    
                                                                    </div>
                                                                ))}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <h2>Pricing Model</h2>
                                                <div className="parity-form">
                                                    <div className="revenue-check-button">
                                                        <ul>
                                                        <li>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check
                                                                            inline
                                                                            label="One time"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-3`}
                                                                        />                                                                    
                                                                    </div>
                                                                ))}
                                                            </li>
                                                            <li>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check
                                                                            inline
                                                                            label="Subscription"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-4`}
                                                                        />                                                                    
                                                                    </div>
                                                                ))}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <h2>Is there mental model barrier for the solution?</h2>
                                                <div className="parity-form">
                                                    <div className="revenue-check-button">
                                                        <ul>
                                                        <li>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check
                                                                            inline
                                                                            label="Yes"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-5`}
                                                                        />                                                                    
                                                                    </div>
                                                                ))}
                                                            </li>
                                                            <li>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check
                                                                            inline
                                                                            label="No"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-6`}
                                                                        />                                                                    
                                                                    </div>
                                                                ))}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                                <tfoot>

                                    <tr>

                                        <td>
                                           
                                            <div class="scrop-alignment-serch Revenuescore-serch">
                                                <h3>Revenue Score</h3>
                                                {/* <div class="form-group">
                                                   
                                                    <input placeholder="Locked" type="text" class="form-control"/>
                                                </div> */}
                                            </div>
                                           
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Purchasedecisionalignment">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Solutionriskscore">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
        </div>
    )
}
