import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Solutionriskscore() {
    return (
        <div>
            <Header2 />
     
        <div className="page-wraper">
            <div className="Probdesc-page">
               
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Solution Risk Scorecard </h2>
                            <p>The solution risk scorecard evaluates if the risks of using the solution for the intended customer/user are acceptable. This is the risk that happens when the solution works as designed. We are not evaluating the risk of service outage here.</p>
                        </div>
                        <div className="table-list Solutionriskscore-table">
                            <Table striped  responsive>
                                <thead>
                                    <tr>
                                        <th>Risk</th>
                                        <th>Select all that apply</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>No perceived or real risk</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 1" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>User's concern of receiving spam emails as a result of purchasing the service</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 2" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>Fear of Loss / theft of private data (Financial account / SSN) as a result of purchasing the service</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 3" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>Other Legal Law Suite Risk</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 4" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>Other Government Compliance Violation Risk</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 5" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>Health Risks</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 6" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>Loss of Life Risk</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 7" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Solutionriskscore-list">
                                                <h2>Other perceived risks</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Revenuescore-parity">
                                                <input aria-label="option 8" type="checkbox" class="form-check-input"/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        
                                        <td colSpan={2}>
                                            <div class="scrop-alignment-serch solutionriskscore-serch">
                                                <h3>Total</h3>
                                                <div class="form-group">
                                                    {/* <select class="form-select" id="formGridState"><option>Locked</option></select> */}
                                                    <Form.Control type="text" placeholder="Locked" />
                                                </div>
                                            </div>
                                           
                                        </td>
                                        
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Revenuescore">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Fundingscore">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
        </div>
    )
}
