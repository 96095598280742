import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap'
import { Container } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function Header() {
  return (
    <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="#home"><img src="../img/logo.png" alt=""></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#Services">Services</Nav.Link>
                <Nav.Link href="#AboutUs">About Us</Nav.Link>
                <Nav.Link href="#ContactUs">Contact Us</Nav.Link>
                <Nav.Link >  <Button variant="primary sign-button"><span><i class="fa-solid fa-user"></i></span><NavLink to="/Sign">Sign In</NavLink></Button></Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}
