import { Container, Form, Row, Button } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function 
() {
  return (
    <div>
        <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
               
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Buyer Motivation Parity Score </h2>
                            <p>Buyer motivation parity informs how likely a target customer is to pay for the services you intend to provide. How important and urgent is it for the customer to solve the problem for which you are providing a solution? And what are your customer's current options to solve the problem? Refer to the chart. Pick a quadrant and then pick a sub-category. Enter the score in the box below.</p>
                            <h3>Enter the chosen score below</h3>
                            <Form>
                                <Form.Group className="below-form" controlId="formBasictext">
                                    <Form.Control type="text" placeholder="" />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="line-chart">
                                        <img src="../img/line-chart.png" alt="" />
                                    </div>
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Customeractorsh">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Problemscorecard">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>   
    </div>
  )
}
