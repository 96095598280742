import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Demandpeak() {
  return (
      <div>
           <Header2 />
      
    <div className="page-wraper">
            <div className="Probdesc-page">
               
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Demand Peak Scorecard</h2>
                            <p>This scorecard notes when the demand for the solution will start picking up? Or the peak has already arrived, or the peak has already passed.</p>
                        </div>
                        <div className="table-list demandpeak-table">
                            <Table striped  responsive>
                                <thead>
                                    <tr>
                                        <th>Cateogry <span>Select One</span></th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="Demandpeak-box">
                                                <h2>Steady Demand</h2>
                                                <Form.Check type="radio" aria-label="radio 1" />
                                            </div>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td>
                                        <div className="Demandpeak-box">
                                                <h2>Demand peak right now</h2>
                                                <Form.Check type="radio" aria-label="radio 2" />
                                            </div>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Demandpeak-box">
                                                <h2>Demand Peak 1 Year Away </h2>
                                                <Form.Check type="radio" aria-label="radio 3" />
                                            </div>
                                        </td>
                                       
                                    </tr>
                                    <tr>
                                        <td>
                                        <div className="Demandpeak-box">
                                                <h2>Demand Peak 3 Year Away</h2>
                                                <Form.Check type="radio" aria-label="radio 4" />
                                            </div>
                                        </td>
                                       
                                    </tr>
                                    <tr>
                                        <td>
                                        <div className="Demandpeak-box">
                                                <h2>Demand Peak More Than 3 years away</h2>
                                                <Form.Check type="radio" aria-label="radio 5" />
                                            </div>
                                        </td>
                                       
                                    </tr>
                                    <tr>
                                        <td>
                                        <div className="Demandpeak-box">
                                                <h2>Demand Unknown</h2>
                                                <Form.Check type="radio" aria-label="radio 6" />
                                            </div>
                                        </td>
                                        
                                    </tr>
                                    
                                </tbody>
                                <tfoot>
                                    <tr>

                                        <td colSpan={2}>
                                        
                                            <div class="scrop-alignment-serch demandpeak-serch">
                                                <h3>Demand Peak Score</h3>
                                                <div class="form-group">
                                                     <input placeholder="Locked" type="text" id="formBasicbuyar" class="form-control" />       
                                                </div>
                                            </div>
                                           
                                        </td>
                                        
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Pmcmalignment">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Results">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
        </div>
  )
}
