import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout2Routing from "./Layout2Routing";
import Dashboard from "./Dashboard";
import Admindashboard from "./Admindashboard";
import Initiative from "./Initiative";
import Probdesc from "./Formcomponent/Probdesc";
import Customeractorsh from "./Formcomponent/Customeractorsh";
import Buyermotivationparity from "./Formcomponent/Buyermotivationparity";
import Problemscorecard from "./Formcomponent/Problemscorecard";
import Problemvalidation from "./Formcomponent/Problemvalidation";
import Solutionscorecard from "./Formcomponent/Solutionscorecard";
import Cruxcompetitive from "./Formcomponent/Cruxcompetitive";
import Cruxalignment from "./Formcomponent/Cruxalignment";
import Cruximpact from "./Formcomponent/Cruximpact";
import Purchasedecisionalignment from "./Formcomponent/Purchasedecisionalignment";
import Revenuescore from "./Formcomponent/Revenuescore";
import Solutionriskscore from "./Formcomponent/Solutionriskscore";
import Fundingscore from "./Formcomponent/Fundingscore";
import Marketingscore from "./Formcomponent/Marketingscore";
import Pmcmalignment from "./Formcomponent/Pmcmalignment";
import Demandpeak from "./Formcomponent/Demandpeak";
import Results from "./Formcomponent/Results";





const Layout2 = () => {
    return (
        <> 
            <Routes>
                <Route path="/" element={<Layout2Routing />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/Initiative" element={<Initiative />} />
                    <Route path="/Admindashboard" element={<Admindashboard />} />
                    <Route path="/Probdesc" element={<Probdesc />} />
                    <Route path="/Customeractorsh" element={<Customeractorsh />} />
                    <Route path="/Buyermotivationparity" element={<Buyermotivationparity />} />
                    <Route path="/Problemscorecard" element={<Problemscorecard />} />
                    <Route path="/Problemvalidation" element={<Problemvalidation />} />
                    <Route path="/Solutionscorecard" element={<Solutionscorecard />} />
                    <Route path="/Cruxcompetitive" element={<Cruxcompetitive />} />
                    <Route path="/Cruxalignment" element={<Cruxalignment />} />
                    <Route path="/Cruximpact" element={<Cruximpact />} />
                    <Route path="/Purchasedecisionalignment" element={<Purchasedecisionalignment />} />
                    <Route path="/Revenuescore" element={<Revenuescore />} />
                    <Route path="/Solutionriskscore" element={<Solutionriskscore />} />
                    <Route path="/Fundingscore" element={<Fundingscore />} />
                    <Route path="/Marketingscore" element={<Marketingscore />} />
                    <Route path="/Pmcmalignment" element={<Pmcmalignment />} />
                    <Route path="/Demandpeak" element={<Demandpeak />} />
                    <Route path="/Results" element={<Results />} />
                   
                </Route>
                {/* <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} /> */}
            </Routes>
        </>
    );
}
 
export default Layout2;