import { Container, Form, Row, Button } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Probdesc() {
    return (
        <div>
            <Header2 />
            <div className="page-wraper">
                <div className="Probdesc-page">
                    <div className="new-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    {/* <h4><i className="fa-solid fa-arrow-left-long"></i> New Initiative</h4> */}
                                </div>
                                <div className="col-md-8">
                                    <ul className="new-title-list">
                                        <li>
                                            <h2>Initiative Name:<span> POS</span></h2>
                                        </li>
                                        <li>
                                            <h2>Scorecard Name:<span> W/0 Res</span></h2>
                                        </li>
                                        <li>
                                            <h2>Version:<span> 2</span></h2>
                                        </li>
                                    </ul>
                                </div>    
                            </div>
                        </div>
                    </div>
                    <Container>
                        <Row>
                            <div className="dimentional-title">
                                <h2>8-Dimentional Problem Definition</h2>
                                <p>Use this canvas to improve your understanding of the problem your initiative is attempting to solve. Also, use this canvas to create consensus amongst the team member on the rationale of the product.</p>
                                <h5>All data is encrypted in the database and in transit. No one else can access your data. Your privacy is guaranteed.</h5>
                            </div>
                            <div className="dimentional-list">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="Problem" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="Situation(s) in which the problem is experienced" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="Who in the situation is facing the problem?" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="Which activity is being done which includes the problem?" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="What are the primary root causes of the problem?" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="What is the ideal outcome?" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="What is the proposed solution?" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dimentional-box">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="How does the proposed solution cause ideal outcome?" as="textarea" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="next-back-button">
                                        <Button variant="primary button-blue"><NavLink to="../../Customeractorsh">Next</NavLink></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>   
        </div>
    )
}
