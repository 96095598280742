
import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function Sing() {
  return (
    <div>
      <div className="login-page">
          <div className="container">
            <h2>Sign In</h2>
            <div className="Quickly-login">
            <Form>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Cameron Williamson" />
              </Form.Group>
              <Form.Group className="mb-3 password" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="********" />
                <span className='check-aero'><i className="fa-solid fa-circle-check"></i></span>
              </Form.Group>
              <div className='forget-link'>
               <NavLink to="/Sign">Forgot Password?</NavLink>
              </div>
              <div className="sign-up-button">
               <Button variant="primary">Submit</Button>{' '}
              </div>
              <div className="allredy0sign">
                 <h3>If you have not an account? <Button variant="link"> <NavLink to="/Register">Sign Up</NavLink></Button></h3>
              </div>
            </Form>
            </div>
          </div>
      </div>
    </div>
  )
}
