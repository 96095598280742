import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function Initiative() {
  return (
    <div>
        <div className="initiative-page">
            {/* <div className="new-title">
            <div className="container">
                <h4><i class="fa-solid fa-arrow-left-long"></i> New Initiative</h4>
                </div>
            </div> */}
          <div className="container">
            <h2>Start evaluation of a new Initiative</h2>
            <div className="Quickly-login">
            <Form>
              <Form.Group className="mb-4" controlId="formBasicInitiative">
                <Form.Label>Initiative Name</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicScorecard">
                <Form.Label>Scorecard Name</Form.Label>
                <Form.Control type="text" placeholder="" />
               
              </Form.Group>
             
              <Form.Group className="mb-4">
              <Form.Label>What’s the situation</Form.Label>
                <Form.Select  aria-label="Default select example">
                  <option>--select one--</option>
                  <option value="1">Evaluate an idea,</option>
                  <option value="2">Evaluating an investment opportunity,</option>
                  <option value="3">Evaluating in development product</option>
                </Form.Select>
              </Form.Group>
              <div className="col-md-12">
              <div className="creat-button">
                 <Button variant="link"><NavLink exact activeClassName="active" to='/admin' >Create</NavLink> </Button>
              </div>
              </div>
             
              <div className="allredy0sign">
                 <h3><NavLink exact activeClassName="active" to='/admin' >Cancel Initiative</NavLink></h3>
              </div>
            </Form>
            </div>
          </div>
      </div>
    </div>
  )
}
