import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Cruxalignment() {
  return (
    <div>
        <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>CRUX - Alignment Scorecard </h2>
                            <p>CRUX - Alignment scorecard evaluates the alignment strength amongst problem, root causes, solution, and business goals.</p>
                        </div>
                       <div className="table-list Cruxalignment-list">
                       <Table striped  responsive>
                            <thead>
                                <tr>
                                <th>Question</th>
                                <th>Ratings</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><h2>Are you able to identify all the root causes for the problem or need?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>5</option>
                                                <option>2</option>
                                                <option>No Go</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Are you able to identify solution differentiators that have significant impact on root cause elimination?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>5</option>
                                                <option>1</option>
                                                <option>No Go</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Are there any frequent root causes that are not solvable?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>-10</option>
                                                <option>-5</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Can you digitally implement the solution differentiators?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>5</option>
                                                <option>2</option>
                                                <option>No Go</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>What is Crux Competitive Score?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>5</option>
                                                <option>-5</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Are you able to clearly identify how a successful product will help your business?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>5</option>
                                                <option>1</option>
                                                <option>No Go</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Is there consensus amongst stakeholders on product business goal alignment?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>5</option>
                                                <option>-1</option>
                                                <option>-5</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                
                            <tr>
                                    <td><h2><b>Total</b></h2></td>
                                    
                                    <td>
                                         <Form.Control placeholder="Locked" disabled />
                                    </td>
                                </tr>
                            </tfoot>
                            </Table>
                       </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Solutionscorecard">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Cruximpact">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>   
    </div>
  )
}
