import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Purchasedecisionalignment() {
  return (
      <div>
          <Header2 />
     
    <div className="page-wraper">
    <div className="Probdesc-page">
        
        <Container>
            <Row>
                <div className="dimentional-title">
                    <h2>Purchase Decision Alignment Scorecard</h2>
                    <p>Purchase decision alignment informs about the customer's interest in paying for your solution, overall alignment in adoption, and financial and other support to the initiative.</p>
                    <h6>Whose problem are you solving? Check all that apply from below.</h6>
                </div>
               <div className="table-list Purchasedecisionalignment">
               <Table striped  responsive >
                   
                    <tbody>
                        <tr>
                            <td>
                                <div className="Purch-checkbox">
                                    <h2>Customer</h2>
                                    <div className="check-box-table">
                                         <Form.Check aria-label="option 1" />
                                    </div>
                                </div>
                           </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="Purch-checkbox">
                                    <h2>Actor</h2>
                                    <div className="check-box-table">
                                         <Form.Check aria-label="option 1" />
                                    </div>
                                </div>
                           </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="Purch-checkbox">
                                    <h2>Stakeholders</h2>
                                    <div className="check-box-table">
                                         <Form.Check aria-label="option 1" />
                                    </div>
                                </div>
                           </td>
                        </tr>
                        
                        
                    </tbody>
                    <tfoot>
                        
                    <tr>
                             
                            <td>
                               
                                    <div class="scrop-alignment-serch">
                                        <h3>Purchase Decision Score</h3>
                                        <div class="form-group">
                                            {/* <select class="form-select" id="formGridState">
                                                <option>Locked</option>
                                            </select> */}
                                            <Form.Control  placeholder="Locked" />
                                        </div>
                                    </div>
                               
                            </td>
                        </tr>
                    </tfoot>
                    </Table>
               </div>
                <div className="buyer-motivation ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="what-video">
                                <h3>Watch the video</h3>
                                <img src="../img/what-video.png" alt="" />
                            </div>
                            <div className="next-back-button">
                                <Button variant="primary button-green"><NavLink to="../../Cruximpact">Back</NavLink></Button>
                                <Button variant="primary button-blue"><NavLink to="../../Revenuescore">Next</NavLink></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    </div>
</div>   
</div>
  )
}
