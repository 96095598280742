import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function 
() {
  return (
    <div>
         <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="result-title">
                                <h2>Result</h2>
                                <Button variant="primary"> <img src="../img/upload.png" alt="" /> Download</Button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="result-go">
                                <h3>The recommendation apply to the current state of the initiative. Review the summary  <br />below and see if you can fix the negative scores as per the  The Takeoff Point pivot framework and re-evaluate it.</h3>
                                <span>No Go</span>
                                <p>Review the summary below and see if you can fix the negative scores as per <br />the The Takeoff Point pivot framework and evaluate again.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="Opportunity-go-box">
                                <div className="Opportunity-go-head">
                                    <h3>Opportunity <br />Score</h3>
                                    <h4>56</h4>
                                </div>
                                <ul>
                                    <li>
                                        <h5>Problem Score</h5>
                                        <h5>28</h5>
                                    </li>
                                    <li>
                                        <h5>Problem Validation Score</h5>
                                        <h5>26</h5>
                                    </li>
                                    <li>
                                        <h5>Revenue Score</h5>
                                        <h5> 5</h5>
                                    </li>
                                    <li>
                                        <h5>Purchase Decision Alignment</h5>
                                        <h5> 5</h5>
                                    </li>
                                    <li>
                                        <h5>Solution Risk Score</h5>
                                        <h5>-5</h5>
                                    </li>
                                    <li>
                                        <h5>Demand Peak</h5>
                                        <h5>-3</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="Opportunity-go-box">
                                <div className="Opportunity-go-head read">
                                    <h3>Execution <br />Score</h3>
                                    <h4>57</h4>
                                </div>
                                <ul>
                                    <li>
                                        <h5>Solution Score</h5>
                                        <h5>-7</h5>
                                    </li>
                                    <li>
                                        <h5>Crux Competitive Score</h5>
                                        <h5> 5</h5>
                                    </li>
                                    <li>
                                        <h5>Crux Alignment Score</h5>
                                        <h5>32</h5>
                                    </li>
                                    <li>
                                        <h5>Crux Impact Score</h5>
                                        <h5>15</h5>
                                    </li>
                                    <li>
                                        <h5>Marketing Score</h5>
                                        <h5> 3</h5>
                                    </li>
                                    <li>
                                        <h5>Funding Score</h5>
                                        <h5> 0</h5>
                                    </li>
                                    <li>
                                        <h5>PMCM Alignment</h5>
                                        <h5>  9</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row recommendation-setions">
                        <div className="col-md-12">
                            <div className="Opportunity-go-box">
                                <div className="Opportunity-go-head">
                                    <h3>Summary and recommendation</h3>   
                                </div>
                                <ul>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Problem Score</h5>
                                            <h5>28</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Problem Validation Score</h5>
                                            <h5>26</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Revenue Score</h5>
                                            <h5>5</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Purchase Decision Alignment</h5>
                                            <h5>5</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Solution Risk Score</h5>
                                            <h5>-5</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Demand Peak</h5>
                                            <h5>-3</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Solution Score</h5>
                                            <h5>-7</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Crux Competitive Score</h5>
                                            <h5>5</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Crux Alignment Score</h5>
                                            <h5>32</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Crux Impact Score</h5>
                                            <h5>15</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Marketing Score</h5>
                                            <h5>3</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>Funding Score</h5>
                                            <h5>0</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                    <li>
                                        <div className="recommendation">
                                            <h5>PMCM Alignment</h5>
                                            <h5>9</h5>
                                        </div>
                                        <p>deseription according to the excel sheet</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="view-summary">
                                {/* <Button className="view-button" variant="link ">View Summary</Button> */}
                                <div className="watch-video">
                                    <img src="../img/youtube2.png" alt="" />
                                    <Button variant="link">Watch the video</Button>
                                </div>
                                <div className="next-back-button">
                                    <Button variant="primary button-green"><NavLink to="../../Demandpeak">Back</NavLink></Button>
                                    <Button variant="primary button-blue"><NavLink to="../../Results">Next</NavLink></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
  )
}
