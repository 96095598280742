import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout2 from "./Admin/Layout2";
import Layout from "./Frontend/Layout";

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path='/*' element={<Layout />} />
        <Route exact path='/admin*' element={<Layout2 />} />
      </Routes>
    </>
  );

};

export default App;