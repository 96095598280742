import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Pmcmalignment() {
  return (
      <div>
           <Header2 />
      
    <div className="page-wraper">
        <div className="Probdesc-page">
            
            <Container>
                <Row>
                    <div className="dimentional-title">
                        <h2>Product - Market - Channel - Model (PMCM) Scorecard</h2>
                        <p>This scorecard evaluates the extent of alignment amongst product - market -channel, and model.</p>
                    </div>
                    <div className="table-list Pmcmalignment-table">
                        <Table striped  responsive>
                            <thead>
                                <tr>
                                    <th>Category </th>
                                    <th>Dimension</th>
                                    <th>Obtained Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2>Market - Channel</h2>
                                    </td>
                                    <td>
                                        <h2>Problem Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Market - Channel</h2>
                                    </td>
                                    <td>
                                        <h2>Problem Validation Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Market </h2>
                                    </td>
                                    <td>
                                        <h2>Market Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Product - Market</h2>
                                    </td>
                                    <td>
                                        <h2>Crux Rectangle Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Product</h2>
                                    </td>
                                    <td>
                                        <h2>Solution Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Market</h2>
                                    </td>
                                    <td>
                                        <h2>Frequnecy of the Problem</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Model</h2>
                                    </td>
                                    <td>
                                        <h2>Revenue Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Product</h2>
                                    </td>
                                    <td>
                                        <h2>Risk Score</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Market</h2>
                                    </td>
                                    <td>
                                        <h2>Motivation Parity</h2>
                                    </td>
                                    <td>
                                         <input placeholder="" type="text" id="formBasicbuyar" class="form-control" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Product - Market - Model</h2>
                                    </td>
                                    <td>
                                        <h2>Business Goals</h2>
                                    </td>
                                    <td>
                                            <select class="form-select" id="formGridState">
                                                <option>Aligned Across Stakeholders</option>
                                                <option>Not aligned across stakeholders</option>
                                            </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Channel</h2>
                                    </td>
                                    <td>
                                        <h2>Best interface option Web/Mobile or<br/> Voice validated with situation the problem <br/> happens? and user srearch?</h2>
                                    </td>
                                    <td>
                                        <select class="form-select" id="formGridState">
                                            <option>--Select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </td>
                                </tr>
                            
                            </tbody>
                            <tfoot>
                                <tr>

                                    <td colSpan={3}>
                                       
                                            <div class="scrop-alignment-serch pmcmalignment-serch">
                                                <h3>Total</h3>
                                                <div class="form-group">
                                                    <input placeholder="Locked" type="text" id="formBasicbuyar" class="form-control" />
                                                </div>
                                            </div>
                                     
                                    </td>
                                    
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                    <div className="buyer-motivation ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="what-video">
                                    <h3>Watch the video</h3>
                                    <img src="../img/what-video.png" alt="" />
                                </div>
                                <div className="next-back-button">
                                    <Button variant="primary button-green"><NavLink to="../../Marketingscore">Back</NavLink></Button>
                                    <Button variant="primary button-blue"><NavLink to="../../Demandpeak">Next</NavLink></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    </div>
    </div>
  )
}
