import React from 'react';
import { Button } from 'react-bootstrap';
import Datatable from "./Datatable";
import { NavLink } from 'react-router-dom';


export default function Dashboard() {
  return (
    <div>
      <div className='dashbord-page'>
          <div className='container'>
              <row>
                <div className='dashboard-title'>
                  <div className='dashboard-patel'>
                      <h3>Dashboard</h3>
                      <h2>Welcome! Hemal Patel</h2>
                  </div>
                  <div className='dashboard-button'>
                  <Button variant="primary"><NavLink to="../../initiative"><i class="fa-solid fa-square-plus"></i> Create New</NavLink></Button>{' '}
                  </div>  
                </div>
                <Datatable/>
              </row>
          </div>
      </div>
    </div>
  )
}
