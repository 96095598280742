import React, { useState } from 'react';
import CountrySelect from 'react-bootstrap-country-select';


export default function Countrylist() {
    const [ value, setValue ] = useState(null); 
  return (
    <div>
         <CountrySelect
        value={value}
        onChange={setValue}
    />
    </div>
  )
}



