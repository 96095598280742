import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";


export default function Marketingscore() {
  return (
      <div>
          <Header2 />
      
    <div className="page-wraper">
        <div className="Probdesc-page">
            
            <Container>
                <Row>
                    <div className="dimentional-title">
                        <h2>Marketing Score</h2>
                        <p>The marketing scorecard is relatively simple. It measures the experience of the team in sales and marketing.</p>
                    </div>
                    <div className="table-list Fundingscore-table">
                        <Table striped  responsive>
                            <thead>
                                <tr>
                                    <th>Marketing expereince category</th>
                                    <th>Select any one option</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Team has prior marketing experience of successfully launching a similar digital product</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                        <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Team has prior marketing experience of successfully launching a product but in a different domain</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                        <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                            
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Team has prior marketing attempt experience but it failed </h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                        <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                            
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Team has no prior marketing experience</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                        <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                            
                               
                            </tbody>
                            <tfoot>
                                <tr>

                                <td colSpan={2}>
                                        <div class="scrop-alignment-serch Fundingscore-serch">
                                            <h3>Total</h3>
                                            <div class="form-group">
                                                
                                                <input placeholder="Locked" type="text" class="form-control"/>
                                                </div>
                                        </div>
                                    </td>
                                    
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                    <div className="buyer-motivation ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="what-video">
                                    <h3>Watch the video</h3>
                                    <img src="../img/what-video.png" alt="" />
                                </div>
                                <div className="next-back-button">
                                    <Button variant="primary button-green"><NavLink to="../../Solutionriskscore">Back</NavLink></Button>
                                    <Button variant="primary button-blue"><NavLink to="../../Pmcmalignment">Next</NavLink></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    </div>
    </div>
  )
}
