import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutRouting from "./LayoutRouting";
import Home from "./Home";
import Sign from "./Sign";
import Register from "./Register";
import Admindashboard from "./Admindashboard";


const Layout = () => {
    return (
        <> 
            <Routes>
                <Route path="/" element={<LayoutRouting />}>
                    <Route index element={<Home/>} />
                    <Route path="/Sign" element={<Sign/>} />
                    <Route path="/Register" element={<Register/>} />
                    <Route path="/Admindashboard" element={<Admindashboard/>} />
                </Route>
                {/* <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} /> */}
            </Routes>
        </>
    );
}
 
export default Layout;