import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Cruximpact() {
  return (
      
    <div>
         <Header2 />
   
    <div className="page-wraper">
       
    <div className="Probdesc-page">
        
        <Container>
            <Row>
                <div className="dimentional-title">
                    <h2>CRUX - Impact Scorecard </h2>
                    <p>CRUX - Impact scorecard evaluates the strength of your core solution - what kind of impact it will generate.</p>
                </div>
               <div className="table-list Cruximpact">
               <Table striped responsive >
                    <thead>
                        <tr>
                        <th>Question</th>
                        <th>Pain point improvement / Game changer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><h2>Time</h2></td>
                            <td>
                                <Form.Group className="form-group" controlId="formGridState">
                                    <Form.Select defaultValue="Choose...">
                                        <option>--Select one--</option>
                                        <option>Significant improvement</option>
                                        <option>No significant imporvement</option>
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td><h2>Money</h2></td>
                            <td>
                                <Form.Group className="form-group" controlId="formGridState">
                                   <Form.Select defaultValue="Choose...">
                                        <option>--Select one--</option>
                                        <option>Significant improvement</option>
                                        <option>No significant imporvement</option>
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td><h2>Effort</h2></td>
                            <td>
                                <Form.Group className="form-group" controlId="formGridState">
                                    <Form.Select defaultValue="Choose...">
                                        <option>--Select one--</option>
                                        <option>Significant improvement</option>
                                        <option>No significant imporvement</option>
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td><h2>Life Saving</h2></td>
                            <td>
                                <Form.Group className="form-group" controlId="formGridState">
                                   <Form.Select defaultValue="Choose...">
                                        <option>--Select one--</option>
                                        <option>Significant improvement</option>
                                        <option>No significant imporvement</option>
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td><h2>Gratitude</h2></td>
                            <td>
                                <Form.Group className="form-group" controlId="formGridState">
                                    <Form.Select defaultValue="Choose...">
                                        <option>--Select one--</option>
                                        <option>Significant improvement</option>
                                        <option>No significant imporvement</option>
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td><h2>Health</h2></td>
                            <td>
                                <Form.Group className="form-group" controlId="formGridState">
                                    <Form.Select defaultValue="Choose...">
                                        <option>--Select one--</option>
                                        <option>Significant improvement</option>
                                        <option>No significant imporvement</option>
                                    </Form.Select>
                                </Form.Group>
                            </td>
                        </tr>
                        
                    </tbody>
                    <tfoot>
                        
                    <tr>
                            <td></td>
                            
                            <td>
                                <div className="total-ft Cruximpact">
                                    <h2><b>Total</b></h2>    
                                   
                                    <Form.Control placeholder="Locked" disabled />
                                </div>
                            
                            </td>
                        </tr>
                    </tfoot>
                    </Table>
               </div>
                <div className="buyer-motivation ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="what-video">
                                <h3>Watch the video</h3>
                                <img src="../img/what-video.png" alt="" />
                            </div>
                            <div className="next-back-button">
                                <Button variant="primary button-green"><NavLink to="../../Cruxalignment">Back</NavLink></Button>
                                <Button variant="primary button-blue"><NavLink to="../../Purchasedecisionalignment">Next</NavLink></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    </div>
</div>   
</div>

  )
}
