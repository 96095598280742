import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";



export default function Problemvalidation() {
  return (
    <div>
        <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
               
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Problem Validation Scorecard </h2>
                            <p>Problem validation is a research-based activity. You fill this scorecard after you conduct customer research. For a first pass evaluation, you can attribute a score that is your first guess in each score below. For the second pass evaluation, you would plug in actual research data. Generally, real research data is vastly different from your initial understanding. Therefore problem validation with actual research is one of the most critical steps.</p>
                        </div>
                       <div className="table-list Problemvalidationtable">
                       <Table striped responsive >
                            <thead>
                                <tr>
                                <th>Validation Topic</th>
                                <th>Validation Below 30%</th>
                                <th>Validation Between 30% to 50%</th>
                                <th>Validation Above 50%</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><h2>Problem or need</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Situation in which the problem is experienced</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h2>Who in the situation is facing the problem?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                             <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td><h2>Which activity is being done which includes the problem?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>

                                <tr>
                                    <td><h2>What are the primary root causes of the problem? What is the ideal outcome?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>

                                <tr>
                                    <td><h2>What is the proposed solution?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>

                                <tr>
                                    <td><h2>How does the proposed solution causes ideal outcome?</h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>

                                
                                
                            </tbody>
                            <tfoot>
                                
                            <tr>
                                    <td><h2><b>Total</b></h2></td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Control placeholder="Locked" disabled />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control placeholder="Locked" disabled />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control placeholder="Locked" disabled />
                                        </Form.Group>
                                    </td>
                                </tr>
                            </tfoot>
                            </Table>
                       </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Problemscorecard">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Solutionscorecard">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>   
    </div>
  )
}
