
import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Solutionscorecard() {
  return (
    <div>
         <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
               
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Solution Scorecard </h2>
                            <p>The solution scorecard evaluates the effectiveness of your solution to solve the problem. It also enables you to understand many critical aspects of the solution.</p>
                        </div>
                    <div className="source-table Solutionscorecard-table">
                    <Table  striped responsive  cellspacing="0" cellpadding="0">
                           
                            <tbody>
                                <tr>
                                    <td colspan="6">
                                    <Table striped   cellspacing="0" cellpadding="0">
                                       
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    
                                                    <Table className="table-pd" striped   cellspacing="0" cellpadding="0">
                                                            <thead>
                                                                <tr>
                                                                <th>Category</th>
                                                                <th>Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><h2>Opportunity Window ( Time available to launch a solution)</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                   
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Expected Solution Life</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Cost of Service</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Time to Results (How long it takes for a customer to finish a meaningful action, interaction session time, series of actions )</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Reputation of Solution Provider</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Time to Market: How soon can you launch</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Time to Revenue: How soon can you make your first $</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Product Team Experience</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Research Experience</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                    <td>
                                                    <Table className="table-pd" striped   cellspacing="0" cellpadding="0">
                                                        <thead>
                                                            <tr>
                                                            <th>Category</th>
                                                            <th>Score</th>
                                                            </tr>
                                                        </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><h2>Effort for results (how much effort a customer has to put in to use your solution)</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Access to solution (customer on boarding effort)</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Domain Expertise</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Reliability: How often your solution works</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Ability to have sustained Competitive advantage for 5 years</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Technology Solution Feasibility</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Budget for building solution</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Tech Talent to build solution</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Team Record</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><h2>Full Control over Tech Stack</h2></td>
                                                                    <td>
                                                                    <Form.Group className="form-group" controlId="formGridState">
                                                                        <Form.Select defaultValue="Choose...">
                                                                            <option>Combo Box</option>
                                                                            <option>-5</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className="table-wh" width="50%">
                                    <h2><b>Total</b></h2>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control placeholder="Locked" disabled />
                                       
                                        </Form.Group>
                                    </td>
                                   
                                    <td className="table-wh-right" width="50%">
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control placeholder="Locked" disabled />
                                        </Form.Group>
                                    </td>
                                </tr>
                            </tfoot>
                            </Table>
                    </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Problemvalidation">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Cruxcompetitive">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>   
    </div>
  )
}
