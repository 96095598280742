import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Countrylist from "./Countrylist.js";



export default function Home() {
  return (
    <div>
      <div className="login-page">
          <div className="container">
            <h2>Quickly tell us a few things</h2>
            <div className="Quickly-login">
            <Form>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control type="email" placeholder="Cameron Williamson" />
              </Form.Group>
              <Form.Group className="mb-4 password" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="cameron.williamson@example.com" />
                <span className='check-aero'><i className="fa-solid fa-circle-check"></i></span>
              </Form.Group>
              <Form.Group className="mb-4 password" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="********" />
                <span className='check-aero'><i className="fa-solid fa-circle-check"></i></span>
                <Form.Text className="text-muted">
                Password need to be at least 8 characters long.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-4">
              <Form.Label>Working as</Form.Label>
                <Form.Select  aria-label="Default select example">
                  <option>Combo</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-4">
              <Form.Label>Country</Form.Label>
              <Countrylist />
              </Form.Group>
              <div className="sign-up-button">
               <Button variant="primary">Submit</Button>{' '}
              </div>
              <div className="allredy0sign">
                 <h3>Already registered?<Button variant="link"> <NavLink to="/Sign">Sign In</NavLink></Button></h3>
              </div>
            </Form>
           
            </div>
          </div>
      </div>
    </div>
  )
}
