import { Container, Form, Row, Button } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Customeractorsh() {
  return (
    <div>
         <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
                <div className="new-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                {/* <h4><i className="fa-solid fa-arrow-left-long"></i> New Initiative</h4> */}
                            </div>
                            <div className="col-md-8">
                                <ul className="new-title-list">
                                    <li>
                                        <h2>Costomer - Actor - Stakeholder</h2>
                                    </li>
                                    
                                </ul>
                            </div>    
                        </div>
                    </div>
                </div>
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Describe who is the customer, actor, and stakeholder of the initiative.</h2>
                            <p><span>Customer:</span>Person who is responsible for paying you and who has authority to cancel usage of your service.</p>
                            <p><span>Actors:</span>Any person or system who interacts with your service in order to accomplish a task. A customer can also be an actor.</p>
                            <p><span>Stakeholder:</span> Group of people interested in the outcome of the initiative. They may or may not be customers or actors.</p>
                        </div>
                        <div className="dimentional-list">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="dimentional-box">
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Control placeholder="Customer" as="textarea" />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dimentional-box">
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Control placeholder="Actor" as="textarea" />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dimentional-box">
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Control placeholder="Stakeholder" as="textarea" />
                                        </Form.Group>
                                    </div>
                                </div>
                                
                                <div className="col-md-12">
                                    <div className="next-back-button">
                                            <Button variant="primary button-green"><NavLink to="../../Probdesc">Back</NavLink></Button>
                                            <Button variant="primary button-blue"><NavLink to="../../Buyermotivationparity">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
         </div>   
    </div>
  )
}
