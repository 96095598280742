import { Container, Form, Row, Button } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function 
() {
  return (
    <div>
        <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
                
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>Problem Scorecard </h2>
                            <p>The problem score inform you of the strength of the problem you are trying to solve? It has a direct relation with your ability to monetize your solution. The overall problem score is seen with respect to the kind of funding and other resources you have.</p>
                        </div>
                        <div className="problem-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="form-group" controlId="formBasicbuyar">
                                        <Form.Label>Buyer Motivation Parity Score</Form.Label>
                                        <Form.Control type="text" placeholder="4" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="form-group"  controlId="formGridState">
                                        <Form.Label>Market Size</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Few Customers</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Frequency of the Problem</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                             <option>--select one--</option>
                                            <option>Low - Low</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Time saving opportunity</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                        <option>--select one--</option>
                                            <option>100x</option>
                                          
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Life of a problem</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                        <option>--select one--</option>
                                            <option>Years</option>
                                            
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Life Saving</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                        <option>--select one--</option>
                                            <option>Yes</option>
                                           
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Convenience / Comfort</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                        <option>--select one--</option>
                                            <option>Yes</option>
                                            
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Regulatory Requirement</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                        <option>--select one--</option>
                                            <option>No</option>
                                           
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Label>Security</Form.Label>
                                        <Form.Select defaultValue="Choose...">
                                        <option>--select one--</option>
                                            <option>Enhance</option>
                                            
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="problem-total">
                                         <span>Total</span>
                                         <Form.Control type="text" placeholder="" />
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Buyermotivationparity">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Problemvalidation">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>   
    </div>
  )
}
