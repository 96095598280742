import React from 'react';
import Datepicker2 from "./Datepicker2";
import { Container, Form, Row, Button, Table } from 'react-bootstrap';


export default function Admindashboard() {
  return (
    <div>
        <div className="Admin-dashboard">
            <Container>
                <row>
                    <div className="admin-deshbord-title">
                        <h2>Admin dashboard</h2>
                        <Datepicker2 />
                    </div>
                    <div className="table-list Problemvalidationtable">
                       <Table striped responsive >
                            <thead>
                                <tr>
                                    <th>Sr no</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                    <th>Title</th>
                                    <th>Regstration Date</th>
                                    <th>Initiative</th>
                                    <th>Scorecard Name</th>
                                    <th>Version</th>
                                    <th>Opportunity Score</th>
                                    <th>Executive Score</th>
                                    <th><Form.Check aria-label="option 1" /></th>
                                    <th><Form.Check aria-label="option 1" /></th>
                                    <th><Form.Check aria-label="option 1" /></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><h2>102</h2></td>
                                    <td><h2>hpatelecom@yahoo.com</h2></td>
                                    <td><h2>India</h2></td>
                                    <td><h2>VP</h2></td>
                                    <td><h2>23 Feb 2022</h2></td>
                                    <td><h2>POS Replacement</h2></td>
                                    <td><h2>First Pass w/o research</h2></td>
                                    <td><h2>1</h2></td>
                                    <td><h2>30</h2></td>
                                    <td><h2>48</h2></td>
                                    <td><h2>Edit</h2></td>
                                    <td><h2>Duplicate</h2></td>
                                    <td><h2>Download</h2></td>
                                    <td><h2>Delete</h2></td>
                                </tr>
                                <tr>
                                    <td><h2>102</h2></td>
                                    <td><h2>hpatelecom@yahoo.com</h2></td>
                                    <td><h2>India</h2></td>
                                    <td><h2>VP</h2></td>
                                    <td><h2>23 Feb 2022</h2></td>
                                    <td><h2>POS Replacement</h2></td>
                                    <td><h2>First Pass w/o research</h2></td>
                                    <td><h2>1</h2></td>
                                    <td><h2>30</h2></td>
                                    <td><h2>48</h2></td>
                                    <td><h2>Edit</h2></td>
                                    <td><h2>Duplicate</h2></td>
                                    <td><h2>Download</h2></td>
                                    <td><h2>Delete</h2></td>
                                </tr>
                                
                                
                                
                               

                               

                                

                                

                                

                                
                            </tbody>
                            
                            </Table>
                       </div>
                </row>
            </Container>
        </div>
        
    </div>
  )
}
