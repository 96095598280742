import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Fundingscore() {
  return (
    <div>
        <Header2 />
    
    <div className="page-wraper">
        <div className="Probdesc-page">
           
            <Container>
                <Row>
                    <div className="dimentional-title">
                        <h2>Funding Score</h2>
                        <p>The funding scorecard is designed to understand how far the available funds will last. Is it enough to build a revenue-making product?</p>
                    </div>
                    <div className="table-list Fundingscore-table">
                        <Table striped  responsive>
                            <thead>
                                <tr>
                                    <th>Funding Option Available </th>
                                    <th>Select any one option </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>2X funding is available till deep differentiator milestone</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                         <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Just enough funding is available till deep differentiator milestone</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                         <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                               
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>2X funding till MRMP</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                         <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                               
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Unlimited sweat equity till MRMP</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                         <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                               
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Unlimited sweat equity till MRMP + funds upto $100,000</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                         <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                               
                                <tr>
                                    <td>
                                        <div className="Solutionriskscore-list">
                                            <h2>Any lower level of funding</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Revenuescore-parity">
                                         <Form.Check type="radio" aria-label="radio 1" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>

                                    <td colSpan={2}>
                                        <div class="scrop-alignment-serch Fundingscore-serch">
                                            <h3>Total</h3>
                                            <div class="form-group">
                                               
                                                <Form.Control type="text" placeholder="Locked" />
                                            </div>
                                        </div>
                                    </td>
                                    
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                    <div className="buyer-motivation ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="what-video">
                                    <h3>Watch the video</h3>
                                    <img src="../img/what-video.png" alt="" />
                                </div>
                                <div className="next-back-button">
                                    <Button variant="primary button-green"><NavLink to="../../Fundingscore">Back</NavLink></Button>
                                    <Button variant="primary button-blue"><NavLink to="../../Marketingscore">Next</NavLink></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    </div>
    </div>
  )
}
