import { Container, Form, Row, Button, Table } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from "./Header2";

export default function Cruxcompetitive() {
  return (
    <div>
        <Header2 />
        <div className="page-wraper">
            <div className="Probdesc-page">
               
                <Container>
                    <Row>
                        <div className="dimentional-title">
                            <h2>CRUX - Competitive Scorecard</h2>
                            <p>CRUX - Competitive score informs about the product's strength compared to the competition. It is a two-pass score. For the first pass evaluation, use your understanding, but for a real dependable score, you must undertake thorough research along the lines of this scorecard.</p>
                        </div>
                       <div className="table-list cruxcompetitive-table">
                      
                       <Table striped  responsive >
                            <thead>
                                <tr>
                                    <th></th>
                                  
                                    <th colSpan={4} style={{padding: "0px 26px"}}>
                                        <div className="competitors-bg">
                                            <h5>Competitors</h5>
                                        </div>
                                    </th>
                                    
                                    <th colSpan={3}></th>
                                    <th colSpan={3} style={{padding: "0px 26px"}}>
                                        <div className="competitors-bg">
                                            <h5>Your Solution is</h5>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Category</th>
                                  
                                    <th><Form.Control type="text" placeholder="Competitors 1 Name" /></th>
                                    <th><Form.Control type="text" placeholder="Competitors 2 Name" /></th>
                                    <th><Form.Control type="text" placeholder="Competitors 3 Name" /></th>
                                    <th><Form.Control type="text" placeholder="Competitors 4 Name" /></th>
                                    
                                    <th>Your Solution</th>
                                    <th>Game Changer</th>
                                    <th>Game Changer Impact</th>
                                    <th>Winner</th>
                                    <th>Loser</th>
                                    <th>Tie</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 1</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 2</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 3</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 4</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 5</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 6</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 7</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Control as="textarea" rows={3} />

                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="different-box">
                                             <h2>Differentiator 8</h2>
                                             <Form.Control type="text" placeholder="Differentiator Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Absent</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                            <option>--select one--</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                        {/* <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" ></textarea> */}
                                        <Form.Control as="textarea" rows={3} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="form-group" controlId="formGridState">
                                            <Form.Select defaultValue="Choose...">
                                                <option>--select one--</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                </tr>
                                
                            </tbody>
                                <tfoot>
                                
                                <tr>
                                       
                                        
                                        <td    colSpan={8}>
                                            <div className="scrop-alignment">
                                                <div className="scrop-alignment-serch Cruxcompetitive-sc">
                                                    <h3>Crux - Competitive Score</h3>
                                                    <Form.Group className="form-group" controlId="formGridState">
                                                        {/* <Form.Select defaultValue="Choose...">
                                                            <option>Locked</option>
                                                        </Form.Select> */}
                                                        <Form.Control type="text" placeholder="" />
                                                    </Form.Group>
                                                </div>
                                               
                                            </div>
                                        </td>
                                       
                                       
                                    </tr>
                                </tfoot>
                           
                            </Table>
                       </div>
                        <div className="buyer-motivation ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="what-video">
                                        <h3>Watch the video</h3>
                                        <img src="../img/what-video.png" alt="" />
                                    </div>
                                    <div className="next-back-button">
                                        <Button variant="primary button-green"><NavLink to="../../Solutionscorecard">Back</NavLink></Button>
                                        <Button variant="primary button-blue"><NavLink to="../../Cruxalignment">Next</NavLink></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>   
    </div>
  )
}
