import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function Header() {


  return (
    
    <div>
      <Navbar bg="light" expand="lg">
          <Container>
              <Navbar.Brand href="#home"><img src="../img/logo.png"></img></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                  <Nav.Link href="#home"><img src="../img/admin.png"></img></Nav.Link>
              </Nav>
              </Navbar.Collapse>
          </Container>
      </Navbar>
      {/* <div className="bottom-navbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul>
                  <li><NavLink exact activeClassName="active" to='/admin' >Dashboard</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Probdesc'>Problem</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Customeractorsh'>Customer</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Problemvalidation'>Validation</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Solutionscorecard'>Solution</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Cruxcompetitive'>CRUX-Competitive</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Cruxalignment'>CRUX-Alignment</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Cruximpact'>CRUX-Impact</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Purchasedecisionalignment'>Purchase Decision</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Revenuescore'>Revenue</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Solutionriskscore'>Solution Risk</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Fundingscore'>Funding</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Marketingscore'>Marketing</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Pmcmalignment'>PMCM</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Demandpeak'>Demand Peak</NavLink></li>
                  <li><NavLink activeClassName="active" to='../Results'>Final Result</NavLink></li>
              </ul> 
            </div>
          </div>
        </div>    
      </div> */}
          
    </div>

    
  )
}


